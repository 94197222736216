import React from "react";
import TextPrimary from "../textPrimary";
import BsIcon from "../bsIcon";
import {monthName} from "../../helpers";
import TextSmall from "../textSmall";
import ContainerFluid from "../containers/container_fluid";
import {useTranslation} from "react-i18next";
import AccordionWrapper from "../accordionWrapper";
import {Link} from "react-router-dom";
import InactiveBadge from "../badges/inactiveBadge";

function SubscriptionCard({subscription, adminLink = false}) {
    const { t } = useTranslation();

    const pastApplications = subscription.applications.filter(application => application.past);
    const futureApplications = subscription.applications.filter(application => !application.past);

    const icon = subscription.combined || subscription.monthly_combined ? <BsIcon icon={subscription.plan.icon?.replace("bi-", "")}/> :
            <span> {subscription.plan.name} <BsIcon icon={subscription.plan.icon?.replace("bi-", "")}/> </span>


    return <ContainerFluid key={subscription.id}
                           className="bg-white my-3 px-3 py-2"
                           shadow={true}
                           noFlex={true}
                           dontWrap={true}>
        <div className="d-flex flex-row align-items-center justify-content-between border-bottom py-1">
            <div className="d-flex align-items-center">
                {adminLink ? <Link onClick={() => window.location.href = `/admin/subscriptions/${subscription.id}/edit`} to='#'><TextPrimary text={subscription.name}/></Link> : <TextPrimary text={subscription.name}/>}
                {!subscription.active && <InactiveBadge />}
            </div>
            {icon}
        </div>

        <div className="d-flex pt-1">
            <div className="d-flex flex-column">
                <span className="small text-secondary">
                    {(!subscription.single && !subscription.monthly && !subscription.monthly_combined) && t('events_left') + " " + subscription.events_left}
                    {(subscription.monthly || subscription.monthly_combined) && subscription.end_date !== "-" && monthName(subscription.end_date)}
                </span>
                <TextSmall text={`${t('validity')} ${subscription.end_date}`}/>
            </div>
        </div>

        <AccordionWrapper classNames="p-0" arrow={false} my='1'
                          button={<span className="text-primary text-end small">{t('details')}</span>}>
            <div className="lh-1 mb-2">
                <TextSmall noSecondary={true} text={`${t('used_subscription_events')}:`}/>
                {pastApplications?.length > 0 ?
                    pastApplications.map(application => <><span key={application.datetime} className='small text-secondary'>
                        {`${application.datetime} - ${application.name_with_location}`}
                    </span><br/></>) :
                    <span className='small text-secondary'> {t('no_subscription_events_yet')} </span>
                }
            </div>

            {futureApplications?.length > 0 && <div className="lh-1">
                <TextSmall noSecondary={true} text={`${t('future_applications')}:`}/>
                {futureApplications.map(application =>
                    <span key={application.datetime} className='small text-secondary'>
                        {`${application.datetime} - ${application.name_with_location}`}
                    </span>
                )}
            </div>}

        </AccordionWrapper>
    </ContainerFluid>
}

export default SubscriptionCard;
