import ContainerFluid from "../containers/container_fluid";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../helpers";
import {Button} from "react-bootstrap";
import BsIcon from "../bsIcon";
import AccordionWrapper from "../accordionWrapper";
import AppliedBadge from "../badges/appliedBadge";
import {useNavigate} from "react-router-dom";

function EventCard({ event, applicationId, subscription, payment, settings, payment_settings, fetchData, props}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const checked = !!applicationId
    const [isProcessing, setIsProcessing] = useState(false);

    const getDeadline = () => {
        let threshold = new Date(event.datetime)
        if (checked) {
            threshold.setHours(threshold.getHours() - settings.application_cancellation_limit)
            return threshold
        } else if (settings.apply_before_event_limit) {
            threshold.setHours(threshold.getHours() - settings.apply_before_event_limit)
            return threshold
        } else {
            return false
        }
    }

    const deadlineToString = () => {
        if (!getDeadline()) return ''

        return `${getDeadline().getHours()}:${getDeadline().getMinutes().toString().padStart(2, '0')}`
    }

    const disabled = () => {
        if (!getDeadline()) return false

        return new Date() > getDeadline()
    }

    const handleClick = async (e) => {
        e.preventDefault()
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);

        if (checked) {
            await performAjaxRequest(
                'DELETE',
                `/applications/${applicationId}`,
                null,
                {success: async () => {
                            await fetchData(); // Wait for fetchData to complete before continuing
                            setIsProcessing(false);
                         },
                         props: props })
        } else {
            if (payment_settings.online_subscription_payment === true && !event.group?.has_active_subscription) {
                navigate(`/events/${event.id}/payment`)
                setIsProcessing(false); // Ensure processing state resets if navigating away
            } else {
                await performAjaxRequest(
                    'POST',
                    '/applications',
                    { event_id: event.id },
                    {success: async () => {
                                await fetchData(); // Wait for fetchData to complete before continuing
                                setIsProcessing(false);
                            },
                            props: props })
            }
        }
    };

    const handleOrderDelete = e => {
        e.preventDefault()
        performAjaxRequest(
            'DELETE',
            `/orders/${payment.order_id}`,
            null,
            {success: fetchData,
                     props: props})
    }

    return <ContainerFluid className=" bg-white my-2 p-3" noFlex={true} shadow={true} dontWrap={true}>
        <div className="d-flex flex-row justify-content-between align-items-center">
            <h5>{event.group.name}</h5>
            {applicationId && <AppliedBadge />}
        </div>
        <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column m-1">
                <span className='small text-secondary fw-bold'>
                    <BsIcon classNames="me-1" icon={'clock'}/>
                    {event.duration}
                </span>
                {event.group?.short_address && <span className='small text-secondary'>
                    <BsIcon classNames="me-1" icon={'geo-alt'}/>
                    {event.group?.short_address}
                </span>
                }
                <span className='small text-secondary'>
                    <BsIcon classNames="me-1" icon={'person-raised-hand'}/>
                    {event.group.teachers_name}
                </span>

                {applicationId && subscription && <span className='small text-secondary'>
                        <BsIcon classNames="me-1" icon={`${subscription.plan.icon.replace("bi-", "")}`}/>
                        {`${subscription.plan.name} ${t('bought')}`}
                     </span>
                }

                {applicationId && !subscription && !payment.state && <span className='small text-secondary'>
                            <BsIcon classNames="me-1" icon={"cash"}/>
                            {t('cash_payment')}
                     </span>
                }

                {applicationId && payment.state === 'pending' && <span className='small text-primary'>
                            <BsIcon classNames="me-1" icon={"credit-card"}/>
                            {t('pending_payment')}
                        </span>
                }

                {applicationId && payment.state === 'failed' && <span className='small text-danger'>
                            <BsIcon classNames="me-1" icon={"exclamation-circle"}/>
                            {t('failed_payment')}
                        </span>
                }

                {!applicationId && event.group?.has_active_subscription &&
                    <span className='small text-secondary'>
                        <BsIcon classNames="me-1"
                                icon={`${event.group?.has_active_subscription.plan.icon.replace("bi-", "")}`}/>
                        {`${event.group?.has_active_subscription.plan.name} ${t('bought')}`}
                    </span>
                }
            </div>
        </div>

        {applicationId && <AccordionWrapper arrow={false} my='0' classNames="px-0 py-1" button={<span className="text-primary text-end small">{t('details')}</span>}>
            {payment.state === 'pending' && <div className="d-flex flex-row justify-content-center pt-1 mb-2">
                <Button variant="primary" className="w-100"
                        onClick={() => window.location.href = payment.session_url}> {t('continue_payment')} </Button>

            </div>}

            {payment.state === 'failed' && <>
                <div className="d-flex flex-row justify-content-center pt-1 mb-2">
                    <Button variant="primary" className="w-100"
                            onClick={e => {
                                handleClick(e);
                                navigate(`/events/${event.id}/payment`, { state: { page: 1, formData: {payment_method: 'online'} }})
                            }
                    }> {t('online_subscription_payment')} </Button>
                </div>
                <div className="d-flex flex-row justify-content-center pt-1 mb-2">
                    <Button variant="white" className="w-100" onClick={handleOrderDelete}> {t('cash_payment')} </Button>
                </div>
            </>}

            {payment_settings.online_subscription_payment === true && !subscription && !payment.state &&
                <div className="d-flex flex-row justify-content-center pt-1 mb-2">
                    <Button variant="primary" className="w-100" onClick={e => {
                        handleClick(e);
                        navigate(`/events/${event.id}/payment`, { state: { page: 1, formData: {payment_method: 'online'} }})}
                    }>
                        {t('online_subscription_payment')}
                    </Button>
                </div>
            }

            <div className="d-flex flex-row justify-content-center pt-1">
                {disabled() ? <span
                    className="alert alert-primary bg-blue-100 text-blue-800 border-0 w-100">{t('unapply_disabled', {deadline: deadlineToString()})}</span> :
                    (subscription || !payment.state) ?
                    <Button variant="white" className="w-100" onClick={handleClick} disabled={isProcessing} > {t('unapply')} </Button> :
                    <a className="fs-6" href="#" onClick={handleClick} >{t('unapply')}</a>}
            </div>
        </AccordionWrapper>}

        {!applicationId &&
            (disabled() ? <span
                    className="alert alert-primary bg-blue-100 text-blue-800 border-0 w-100">{t('apply_disabled', {deadline: deadlineToString()})}</span> :
                <div className="d-flex flex-row justify-content-center pt-2">
                    <Button variant="primary" className="w-100" onClick={handleClick} disabled={isProcessing}> {t('apply')} </Button>
                </div>)
        }
    </ContainerFluid>
}

export default EventCard;