import React, {useState} from "react";
import BasicForm from "../basicForm";
import BsIcon from "../../bsIcon";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import Submit from "../fields/submit";
import Back from "../fields/back";
import {performAjaxRequest} from "../../../helpers";

function SubscriptionPaymentSecondStep({event, ...props}) {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = () => {
        if (isSubmitting) return; // Prevent multiple clicks
        setIsSubmitting(true);

        performAjaxRequest(
            'POST',
            '/applications',
            { event_id: event.id },
            {props: props, success: createOrder, error: () => setIsSubmitting(false) })
    }

    const createOrder = () => {
        const plan = event.group.plans.find(plan => plan.id.toString() === props.formData.plan_id)

        performAjaxRequest(
            'POST',
            '/orders',
            {order: {
                    line_items_attributes: [
                        {
                            product_type: 'Subscription',
                            product_attributes: {
                                plan_id: props.formData.plan_id,
                                group_id: plan["combined"] ? null : event.group.id,
                                event_id: event.id
                            }
                        }
                    ]
                }},
            { success: () => setIsSubmitting(false), error: () => setIsSubmitting(false) }
        )
    }


    if (event) { return <BasicForm onSubmit={onSubmit}>
        <div className="d-flex flex-column m-3">
            <h5>{event.group.name}</h5>
            <span className='small text-secondary'>
                <BsIcon classNames="me-1" icon={'calendar-event'}/>
                {event.formated_time}
            </span>
            {event.group?.short_address && <span className='small text-secondary'>
                <BsIcon classNames="me-1" icon={'geo-alt'}/>
                {event.group?.short_address}
            </span>}
            <span className='small text-secondary mb-3'>
                <BsIcon classNames="me-1" icon={'person-raised-hand'}/>
                {event.group.teachers_name}
            </span>
        </div>

        <Form.Group className="m-3">
            <Form.Label className="mb-2">{t('which_classes')}</Form.Label>
            {event.group.plans.map((plan, index) => <FormCheck key={plan.id} className="mb-2" isInvalid={props.formData.plan_id === ''}>
                <FormCheck.Input onChange={props.handleInputChange}
                                 checked={props.formData.plan_id === plan.id.toString()}
                                 className="mb-2 border border-secondary"
                                 type="radio"
                                 id={plan.id}
                                 name='plan_id'
                                 value={plan.id}
                                 required/>
                <FormCheck.Label className="text-black" htmlFor={plan.id} style={{width: "100%"}}>{plan.name}</FormCheck.Label>
                {index + 1 === event.group.plans.length && <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>}
            </FormCheck>)}
        </Form.Group>

        <Submit text={t("next_to_payment")} disabled={isSubmitting} />
        <Back previousPage={props.page - 1} {...props} />
    </BasicForm>
    } else {
        return <p>{t('loading')}</p>
    }
}

export default SubscriptionPaymentSecondStep;