import React, {useEffect, useState} from "react";
import Autocomplete from "../../components/autocomplete";
import {performAjaxRequest} from "../../helpers";
import {useTranslation} from "react-i18next";
import UsersTable from "../../components/tables/usersTable";
import {Button, Pagination} from "react-bootstrap";
import UsersFilter from "../../components/forms/filters/userFilters";
import BsIcon from "../../components/bsIcon";

function UsersPage() {
    const { t } = useTranslation();
    const [totalCount, setTotalCount] = useState(0);
    const [users, setUsers] = useState(null);
    const [page, setPage] = useState(1);
    const maxPages = Math.ceil(totalCount / 30);

    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedKind, setSelectedKind] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        performAjaxRequest(
            'GET',
            '/admin/users.json',
            {term: searchTerm, group: selectedGroup, kind: selectedKind, email_confirmed: selectedStatus, page: page},
            {success: response => {
                    setUsers(response.users);
                    setTotalCount(response.total_count);
                }})
    }, [searchTerm, selectedGroup, selectedKind, selectedStatus, page]);

    const modifyButton = user => (<Button variant="white" type="submit" onClick={() => window.location.href = `users/${user.id}/edit`}>
        {t('admin.modify')}
    </Button>)

    let items = [];

    const startPage = Math.max(1, page - 1); // Show one page before current
    const endPage = Math.min(maxPages, page + 1); // Show one page after current

    if (startPage > 1) {
        items.push(
            <Pagination.Item key={1} onClick={() => setPage(1)}>
                1
            </Pagination.Item>
        );
        if (startPage > 2) {
            items.push(<Pagination.Ellipsis key="ellipsis-start" disabled />);
        }
    }
    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === page}
                onClick={() => setPage(number)}
            >
                {number}
            </Pagination.Item>
        );
    }
    if (endPage < maxPages) {
        if (endPage < maxPages - 1) {
            items.push(<Pagination.Ellipsis key="ellipsis-end" disabled />);
        }
        items.push(
            <Pagination.Item key={maxPages} onClick={() => setPage(maxPages)}>
                {maxPages}
            </Pagination.Item>
        );
    }

    const params = new URLSearchParams({term: searchTerm, group: selectedGroup, kind: selectedKind, email_confirmed: selectedStatus});

    return <>
        <div className="col-md-3">
            <Autocomplete url={`/admin/users.json?group=${selectedGroup}&kind=${selectedKind}&email_confirmed=${selectedStatus}`} sourceKey="users" selectCallback={(event, ui) => {
                setSearchTerm(ui.item.full_name)}
            } />
        </div>
        <UsersFilter selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}
                     selectedKind={selectedKind} setSelectedKind={setSelectedKind}
                     setSelectedStatus={setSelectedStatus} />

        <UsersTable users={users} actionColumn={modifyButton} />
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
            <span className="mt-4 mb-2 mb-md-0">
                {maxPages > 1 ? 30 : totalCount} {t('admin.dancer')} a {totalCount}-ből - {t('admin.download')} <a href={`/admin/users.csv?${params.toString()}`} download>CSV</a>
            </span>

            {maxPages > 1 &&
            <div className="d-flex justify-content-center mt-md-4">
                <Pagination size="sm">
                    <Pagination.Item disabled={page === 1}  onClick={() => setPage(1)}>
                        <BsIcon icon="arrow-left" />
                    </Pagination.Item>
                    <Pagination.Item disabled={page === 1}  onClick={() => setPage(page-1)}>{t("admin.pagination.previous")}</Pagination.Item>
                    {items}
                    <Pagination.Item disabled={page === maxPages}  onClick={() => setPage(page + 1)}>{t("admin.pagination.next")}</Pagination.Item>
                    <Pagination.Item disabled={page === maxPages}  onClick={() => setPage(maxPages)}>
                        <BsIcon icon="arrow-right" />
                    </Pagination.Item>
                </Pagination>
            </div>
            }
        </div>
    </>
}

export default UsersPage;
