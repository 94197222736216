import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import ProfilePage from "./profilePage";
import NotificationSettingsPage from "./profile/notificationSettingsPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import '../i18n';
import LoginPage from "./loginPage";
import RegistrationPage from "./registrationPage";
import PasswordResetPage from "./passwordResetPage";
import MobileFooter from "../components/navs/mobileFooter";
import WorkshopsPage from "./workshopsPage";
import WorkshopApplyPage from "./workshopApply";
import AdminWsApplicationsPage from "./admin/wsApplications";
import AdminWsEventsPage from "./admin/wsEvents";
import PasswordEditPage from "./passwordEditPage";
import EventsPage from "./eventsPage";
import SubscriptionsPage from "./profile/subscriptionsPage";
import GroupsPage from "./profile/groupsPage";
import PersonalPage from "./profile/personalPage";
import DesktopHeader from "../components/navs/desktopHeader";
import MobileHeader from "../components/navs/mobileHeader";
import FlashMessage from "../components/flash_message";
import {performAjaxRequest, setUpAnalytics} from "../helpers";
import DataPolicyPage from "./dataPolicyPage";
import AdminWsApplicationsEventsPage from "./admin/wsApplicationsEvents";
import AdminApplicationsPage from "./admin/applications";
import UserEditPage from "./admin/userEditPage";
import AdminReportsPage from "./admin/reportsPage";
import {useTranslation} from "react-i18next";
import UsersPage from "./admin/usersPage";
import EventPaymentPage from "./eventPaymentPage";

const root_div = document.getElementById('react_root')
const root = ReactDOM.createRoot(root_div);

const logoPath = root_div ? root_div.dataset.logoPath : '';

function App() {
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const [joker, setJoker] = useState(null);
    const [preferences, setPreferences] = useState(null);
    const [groupIds, setGroupIds] = useState(null);
    const [flash, setFlash] = useState(null);
    const [hideKeys, setHideKeys] = useState(null);

    useEffect(() => {
        performAjaxRequest(
            'GET',
            '/currents',
            null,
            {success: response => {setData(response)}
        });
    }, []);

    useEffect(() => {
        if (data) {
            const { user_attributes } = data;
            const { joker: initialJoker, preferences: initialPreferences, groups: initialGroups } = user_attributes || {};

            setJoker(initialJoker);
            setPreferences(initialPreferences);
            setGroupIds(initialGroups ? initialGroups.map(group => group.id) : null);
            setFlash(data.flash);

            setUpAnalytics(data.company_attributes.settings.facebook_pixel_id)
        }
    }, [data]);

    if (!data) {
        return <div>{t('loading')}</div>;
    }

    const { user_attributes, flippers, company_attributes } = data;
    const { subdomain, settings, payment_settings, billing_information } = company_attributes || {};

    return <BrowserRouter>
        <DesktopHeader logoPath={logoPath} currentUser={user_attributes} flippers={flippers} subdomain={subdomain}/>
        <MobileHeader logoPath={logoPath} currentUser={user_attributes} flippers={flippers} subdomain={subdomain}/>
        <FlashMessage flash={flash} hideKeys={hideKeys} setFlash={setFlash}/>

        <Routes>
            <Route path='/' element={<LoginPage setFlash={setFlash} setHideKeys={setHideKeys} />}/>
            <Route path='/sign_in' element={<LoginPage setFlash={setFlash} setHideKeys={setHideKeys} />}/>
            <Route path='/cookie-policy' element={<div className="card bg-white shadow border-0" style={{marginBottom: "100px"}}><div id="cookie-preferences" className="card-body"></div></div>}/>
            <Route path='/password_resets/new' element={<PasswordResetPage setFlash={setFlash} setHideKeys={setHideKeys} />}/>
            <Route path='/password_resets/:token/edit' element={<PasswordEditPage setFlash={setFlash} setHideKeys={setHideKeys} />}/>
            <Route path='/events' element={<EventsPage setFlash={setFlash} setHideKeys={setHideKeys} currentUser={user_attributes} settings={settings} payment_settings={payment_settings}/>}/>
            {payment_settings.online_subscription_payment && <Route path='/events/:id/payment' element={<EventPaymentPage setFlash={setFlash} setHideKeys={setHideKeys} />}/>}
            <Route path='/workshops' element={<WorkshopsPage/>}/>
            <Route path='/workshops/:id/apply' element={<WorkshopApplyPage currentUser={user_attributes} setFlash={setFlash} setHideKeys={setHideKeys} subdomain={subdomain} settings={settings} company_attributes={company_attributes}/>}/>
            <Route path='/users/new' element={<RegistrationPage setFlash={setFlash} setHideKeys={setHideKeys} subdomain={subdomain} settings={settings} billing_information={billing_information}/>}/>
            <Route path='/users/:id' element={<ProfilePage/>}/>
            <Route path='/users/:id/notification_settings' element={<NotificationSettingsPage currentUser={user_attributes}
                                                                                              preferences={preferences}
                                                                                              setPreferences={setPreferences}/>} />
            <Route path='/users/:id/subscriptions' element={<SubscriptionsPage currentUser={user_attributes} />} />
            <Route path='/users/:id/groups' element={<GroupsPage currentUser={user_attributes}
                                                                 groupIds={groupIds}
                                                                 setGroupIds={setGroupIds}
                                                                 setFlash={setFlash} setHideKeys={setHideKeys} />} />
            <Route path='/users/:id/personal' element={<PersonalPage currentUser={user_attributes}
                                                                     joker={joker}
                                                                     setJoker={setJoker} />} />
            <Route path='/data_policy' element={<DataPolicyPage billing_information={billing_information} />} />

            <Route path="/admin/*">
                <Route path='users' element={<UsersPage currentUser={user_attributes} setFlash={setFlash} setHideKeys={setHideKeys} />} />
                <Route path='users/:id/edit' element={<UserEditPage currentUser={user_attributes} setFlash={setFlash} setHideKeys={setHideKeys} />} />
                <Route path='events/:id/applications' element={<AdminApplicationsPage currentUser={user_attributes} setFlash={setFlash} setHideKeys={setHideKeys} />}/>
                <Route path='workshops/:id/ws_applications' element={<AdminWsApplicationsPage currentUser={user_attributes} setFlash={setFlash} setHideKeys={setHideKeys} />}/>
                <Route path='workshops/:id/ws_events' element={<AdminWsEventsPage currentUser={user_attributes} setFlash={setFlash} setHideKeys={setHideKeys} />}/>
                <Route path='ws_events/:id/ws_applications_events' element={<AdminWsApplicationsEventsPage currentUser={user_attributes} setFlash={setFlash} setHideKeys={setHideKeys} />}/>
                <Route path='reports' element={<AdminReportsPage />}/>
            </Route>
        </Routes>

        <MobileFooter currentUser={user_attributes} flippers={flippers}/>
    </BrowserRouter>
    }

root.render(<App/>)
